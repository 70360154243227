import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDestinos, getTypeTicket, getUsoCfdi, getRegimenFiscal, getFactura } from '../services/main';

const initialState = {
    step: 'ingresaDatos',
    error: {
        show: false,
        message: ''
    },
    infoMessage: {
        show: false,
        message: '',
        title: ''
    },
    loading: 'idle',
    loadingMessage: undefined,
    destinos: undefined,
    ticketsTypes: undefined,
    usoCfdi: undefined,
    regimenFiscal: undefined,
    ticketsAdded: undefined,
    ticketsSelected: undefined,
    facturaInformation: undefined,
    preFactura: undefined,
    facturacionBody: undefined,
    registroBody: undefined,
    factura: undefined
}

export const fetchDestinos = createAsyncThunk(
    'steps/getDestinos',
    async () => {
      const response = await getDestinos();
      const json = await response.json();
      return json.data;
    }
);

export const fetchTicketsTypes = createAsyncThunk(
    'steps/getTypeTicket',
    async () => {
      const response = await getTypeTicket();
      const json = await response.json();
      return json.data;
    }
);

export const fetchUsoCfdi = createAsyncThunk(
    'steps/getUsoCfdi',
    async ({rfc, regimenFiscal}) => {
      const response = await getUsoCfdi(rfc, regimenFiscal);
      const json = await response.json();
      return json.data;
    }
);
export const limpiarUsoCfdi = createAsyncThunk(
    'steps/getUsoCfdi',
    []
);

export const fetchRegimenFiscal = createAsyncThunk(
    'steps/getRegimenFiscal',
    async (rfc) => {
        if (rfc) {
            const response = await getRegimenFiscal(rfc);
            const json = await response.json();
            return json.data;
        } else {
            return [];
        }
    }
);

export const fetchPreFactura = createAsyncThunk(
    'steps/getPreFactura',
    async (body) => {
      const response = await getFactura(body);
      const json = await response.json();      
      return json;
    }
);

export const fetchFactura = createAsyncThunk(
    'steps/getFactura',
    async (body) => {
      const response = await getFactura(body);
      const json = await response.json();
      return json;
    }
);

export const stepsSlice = createSlice ({
    name: 'steps',
    initialState,
    reducers: {
        setStep: (state, action) => {
            state.step = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setTicketsAdded: (state, action) => {
            state.ticketsAdded = action.payload
        },
        setTicketsSelected: (state, action) => {
            state.ticketsSelected = action.payload
        },
        setInfoMessage: (state, action) => {
            state.infoMessage = action.payload
        },
        setFacturaInfo: (state, action) => {
            state.facturaInformation = action.payload
        },
        setFacturaBody: (state, action) => {
            state.facturacionBody = action.payload
        },
        setRegistroBody: (state, action) => {
            state.registroBody = action.payload
        },
        setFactura: (state, action) => {
            state.factura = action.payload
        },
        setUsoCfdi: (state, action) => {
            state.usoCfdi = []
        },
        resetState: (state) => {
            state.ticketsAdded = undefined;
            state.ticketsSelected = undefined;
            state.facturaInformation = undefined;
            state.preFactura = undefined;
            state.facturacionBody = undefined;
            state.factura = undefined;
        },
        startLoading: (state) => {
            state.loading = 'loading';
        },
        endLoading: (state) => {
            state.loading = 'idle'
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDestinos.pending, (state) => {
                state.loading = 'loading'
                state.loadingMessage = ''
            })
            .addCase(fetchDestinos.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.loadingMessage = ''
                state.destinos = action.payload;
            })
            .addCase(fetchTicketsTypes.pending, (state) => {
                state.loading = 'loading'
                state.loadingMessage = ''
            })
            .addCase(fetchTicketsTypes.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.loadingMessage = ''
                state.ticketsTypes = action.payload;
            })
            .addCase(fetchUsoCfdi.pending, (state) => {
                state.loading = 'loading'
                state.loadingMessage = ''
            })
            .addCase(fetchUsoCfdi.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.loadingMessage = ''
                state.usoCfdi = action.payload;
            })
            .addCase(fetchRegimenFiscal.pending, (state) => {
                state.loading = 'loading'
                state.loadingMessage = ''
            })
            .addCase(fetchRegimenFiscal.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.loadingMessage = ''
                state.regimenFiscal = action.payload;
            })
            .addCase(fetchPreFactura.pending, (state) => {
                state.loading = 'loading';
                state.loadingMessage = ''
            })
            .addCase(fetchPreFactura.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.loadingMessage = ''
                state.preFactura = action.payload;
            })
            .addCase(fetchFactura.pending, (state) => {
                state.loading = 'loading'
                state.loadingMessage = 'Generando tu factura, espera por favor.'
            })
            .addCase(fetchFactura.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.loadingMessage = ''
                state.factura = action.payload;
            })
    }
})

export const { setStep, setError, setInfoMessage, setTicketsAdded, setTicketsSelected, setFacturaInfo, setFacturaBody, setRegistroBody, setFactura, setUsoCfdi, resetState, startLoading, endLoading } = stepsSlice.actions;

export const selectStep = (state) => state.step.step;
export const stepsState = (state) => state.step;

export default stepsSlice.reducer;
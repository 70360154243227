export const ER_IDENTIFIER = 'AMP521016875';
export const ER_KEY = 'ER';
export const EBUS_KEY = 'ebus';

export const constants = {
  ER_IDENTIFIER: 'AMP521016875',
  ER_KEY: 'ER',
  EBUS_KEY: 'ebus',
  CLAVE_ESTACIONAMIENTO: 'ESTACIONAMIENTO',
  CLAVE_AUTOBUS: 'AUTOBUS',
  CLAVE_BONOBUS: 'BONOBUS',
  CLAVE_PASAPORTE: 'PASAPORTE',
  CLAVE_VENTA_MISCELANEA: 'VENTAMISCELANEA'
}

export const erBrandData = {
  mail: "atencion.cliente@estrellaroja.com.mx",
  phone: "222 2738 300",
  whatsapp: "(222) 5260 000",
  whatsappLink: "2225260000",
  facebookUrl: "https://www.facebook.com/EstrellaRojaPuebla/",
  twitterUrl: "https://twitter.com/estrellarojapue",
  instagramUrl: "https://www.instagram.com/estrellaroja_puebla",
  youtubeUrl: "https://www.youtube.com/channel/UCQWshfti8IfJjMGKLR6zRsg",
  linkedInUrl: "https://www.linkedin.com/company/estrella-roja-puebla/",
  origenesUrl: "https://www.estrellaroja.com.mx/destinos",
  politicasPrivacidadUrl: "https://www.estrellaroja.com.mx/privacidad",
  politicasViajeUrl: "https://www.estrellaroja.com.mx/politicas"
}

export const ebusBrandData = {
  mail: "contact@ebus.mx",
  phone: "(222) 273 8341",
  whatsapp: "(222) 2738 300",
  whatsappLink: "2222738300",
  facebookUrl: "https://www.facebook.com/EBUSmx/",
  twitterUrl: "https://twitter.com/ebusmx",
  instagramUrl: "https://www.instagram.com/ebusmx/",
  origenesUrl: "https://ebus.mx/#puntos-abordaje",
  politicasPrivacidadUrl: "https://ebus.mx/privacidad",
  politicasViajeUrl: "https://ebus.mx/terminos"
}
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { selectBrand, setBrand, setData, selectBrandData } from './reducers/brandingSlice';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Home } from './facturacion/home';
import { GenerarFactura } from './facturacion/generar-facturacion';
import { MisFacturas } from './facturacion/misFacturas';
import { ModalPasos } from './facturacion/modalPasos';
import { setError, setInfoMessage, stepsState } from './reducers/stepsSlice';
import { constants, ebusBrandData, erBrandData } from './facturacion/constants';
import { getRedContacto } from './services/main';
import iconER from './assets/logo/ER.png';
import iconEB from './assets/logo/EBus.png';
import { ModalConfirmar } from './facturacion/modalConfirmar';
import { useModal } from './hooks';

export function Facturacion() {
  const {
    isShowModalConfirmar, handleConfirm, titulo, descripcion, mensajeImportante,
    showModalConfirmar, setMensajes, setHandleConfirmar,
  } = useModal()
  /**
   * Brand/Theming 
   */
  const brand = useSelector(selectBrand);
  const brandData = useSelector(selectBrandData);

  /**
   * Step Handle
   */
  const stepsData = useSelector(stepsState);
  const dispatch = useDispatch();

  /**
   * View Handle
   */
  const [footerType, setFooterType] = useState('wrapper');
  const [redes, setRedes] = useState(null);

  /**
   * Loader Handle
   */
  const [loading, setLoading] = useState(true);

  /**
   * Modals Handle
   */
  const [stepsExamples, setStepsExamples] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  /**
   * Show example modal
   */
  const showExamples = () => {
    setStepsExamples(!stepsExamples)
  }

  /**
   * Change current brand (only when enviroment is DEV)
   */
  const changeBrand = (brandName) => {
    if (brandName === constants.ER_KEY) {
      dispatch(setBrand(constants.ER_KEY));
      dispatch(setData(erBrandData));
    } else {
      dispatch(setBrand(constants.EBUS_KEY));
      dispatch(setData(ebusBrandData));
    }
  }

  /**
   * Error modal handle
   */
  const showErrorModal = (type, message, title) => {
    const messageTxt = message;
    if (type === 'error') {
      if (stepsData.error.show) {
        dispatch(setError({ show: false, message: '' }))
      } else {
        dispatch(setError({ show: true, message: message ? message : 'Algo salio mal' }));
      }
    } else {
      if (stepsData.infoMessage.show) {
        dispatch(setInfoMessage({ show: false, message: messageTxt, title: '' }));
      } else {
        dispatch(setInfoMessage({ show: true, message: messageTxt, title }));
      }
    }
  }

  /**
   * Check if the data is from a social network
   */
  const isSocialNetwork = (resp) => {
    if (
      resp.redSocialContactoAf.cveRedSocialContactoAF.toLowerCase() === 'facebook' ||
      resp.redSocialContactoAf.cveRedSocialContactoAF.toLowerCase() === 'twitter' ||
      resp.redSocialContactoAf.cveRedSocialContactoAF.toLowerCase() === 'instagram' ||
      resp.redSocialContactoAf.cveRedSocialContactoAF.toLowerCase() === 'youtube' ||
      resp.redSocialContactoAf.cveRedSocialContactoAF.toLowerCase() === 'linkedin'
    ) {
      return true
    } else {
      return false
    }
  }

  /**
   * React Hook
   */
  useEffect(() => {
    if (process.env.REACT_APP_BRAND !== 'DEV') {
      const actualBrand = process.env.REACT_APP_BRAND === 'EBUS' ? constants.EBUS_KEY : constants.ER_KEY;
      process.env.REACT_APP_BRAND === 'EBUS' ? dispatch(setData(ebusBrandData)) : dispatch(setData(erBrandData));
      dispatch(setBrand(actualBrand));
    }

    setLoading(false);
    if (stepsData.loading === 'loading') {
      setLoading(true);
    } else {
      setLoading(false);
    }
    if (!redes) {
      const brandkey = process.env.REACT_APP_BRAND === 'ER' ? 'estrellaRoja' : 'ebus';
      getRedContacto(brandkey).then(async resp => {
        const data = await resp.json();
        setRedes(data.data.configuracion);
      })
    }

    if (stepsData.step === 'ingresaDatos' || stepsData.step === 'datosFiscales') {
      setFooterType('wrapper social-only');
    } else {
      setFooterType('wrapper');
    }
  }, [dispatch, stepsData, footerType])

  return (
    <HelmetProvider>
      <div className={`main ${brand}`}>
        <Helmet>
          {process.env.REACT_APP_BRAND === 'ER'
            ? <>
              <link rel="icon" href={iconER} />
              <title>Facturación ER</title>
              <meta
                name="description"
                content="Bienvenido a facturación de Estrella Roja. Ingresa aquí para generar tu factura de manera fácil y sencilla."
              />
            </>
            : <>
              <link rel="icon" href={iconEB} />
              <title>Facturación EBus</title>
              <meta
                name="description"
                content="Bienvenido a facturación de EBUS. Ingresa aquí para generar tu factura de manera fácil y sencilla."
              />
            </>
          }

        </Helmet>
        <div className={loading ? 'loader general active' : 'loader general'}>
          <div className="center">
            <img className="load-detail" src={`${process.env.PUBLIC_URL}/assets/detail-2-${brand}.svg`} alt="detail" />
            <img className="load-detail" src={`${process.env.PUBLIC_URL}/assets/detail-4-${brand}.svg`} alt="detail" />
          </div>
          <div className="center">
            <p>{stepsData.loadingMessage}</p>
          </div>
        </div>

        <Router>
          <header>
            <div className="wrapper">
              <Link onClick={() => setShowMenu(false)} to="/">
                {brand &&
                  <img src={`${process.env.PUBLIC_URL}/assets/logo-${brand.toLowerCase()}.svg`} alt={brand} />
                }
              </Link>
              <button onClick={() => setShowMenu(!showMenu)} className="menu-btn">
                <img src={`${process.env.PUBLIC_URL}/assets/menu.svg`} alt="menu" />
              </button>
              <div className={showMenu ? 'menu open' : 'menu'}>
                <p style={{ cursor: 'pointer' }} onClick={() => showExamples()}>¿Cómo Facturo?</p>
                <Link onClick={() => setShowMenu(!showMenu)} to="/mis-facturas">Consultar Mis Facturas</Link>
              </div>
            </div>
          </header>
          {process.env.REACT_APP_BRAND === 'DEV' &&
            <nav className="temp">
              <button onClick={() => changeBrand("ER")}>ER</button>
              <button onClick={() => changeBrand("ebus")}>Ebus</button>
            </nav>
          }
          <Switch>
            <Route exact path="/">
              <Home setLoading={setLoading} loading={loading} />
            </Route>
            <Route path="/generar-factura">
              <GenerarFactura
                showErrorModal={showErrorModal}
                showModalConfirmar={showModalConfirmar} setMensajes={setMensajes}
                setHandleConfirmar={setHandleConfirmar}
              />
            </Route>
            <Route path="/mis-facturas">
              <MisFacturas showErrorModal={showErrorModal} setLoading={setLoading} loading={loading} />
            </Route>
            <Route path="*">
              <Home />
            </Route>
          </Switch>
          <footer>
            <div className={footerType}>
              {brand === 'ER' &&
                <>
                  <div className='pleca'>
                    <img src={`${process.env.PUBLIC_URL}/assets/pleca-ER.svg`} alt="detail" />
                    <img src={`${process.env.PUBLIC_URL}/assets/pleca-ER.svg`} alt="detail" />
                  </div>
                </>
              }
              <div className="links">
                <p>© Copyright 2021 Estrella Roja</p>
                <a target="_blank" rel="noreferrer" href={brandData.origenesUrl}>Orígenes y Destinos</a>
                <a target="_blank" rel="noreferrer" href={brandData.politicasPrivacidadUrl}>Políticas de Privacidad</a>
                <a target="_blank" rel="noreferrer" href={brandData.politicasViajeUrl}>Políticas de viaje</a>
              </div>
              <div className="social">
                {redes && redes.length > 0 &&
                  redes.map((red, index) => {
                    return isSocialNetwork(red) &&
                      <a href={red.url} target="_blank" rel="noreferrer" key={index}>
                        <img src={`${process.env.PUBLIC_URL}/assets/${red.cuentaNumero.toLowerCase()}.svg`} alt={red.cuentaNumero.toLowerCase()} />
                      </a>
                  })
                }
              </div>
              <div className="phones">
                {redes &&
                  redes.map((red, index) => {
                    return !isSocialNetwork(red) &&
                      <div key={index}>
                        <img src={`${process.env.PUBLIC_URL}/assets/${red.redSocialContactoAf.cveRedSocialContactoAF.toLowerCase()}.svg`} alt={red.redSocialContactoAf.cveRedSocialContactoAF.toLowerCase()} />
                        <a target="_blank" rel="noreferrer" href={`${red.redSocialContactoAf.cveRedSocialContactoAF.toLowerCase() === 'whatsapp' ? 'https://wa.me/' : 'tel:'}${red.url}`}>{red.cuentaNumero}</a>
                      </div>
                  })
                }
              </div>
            </div>
          </footer>

          <div className={stepsExamples ? 'modal-wrapper active' : 'modal-wrapper'}>
            <ModalPasos showExamples={showExamples} />
          </div>

          <div className={isShowModalConfirmar ? 'modal-wrapper active' : 'modal-wrapper'}>
            <ModalConfirmar
              showModal={showModalConfirmar}
              handleConfirm={handleConfirm}
              titulo={titulo} descripcion={descripcion} mensajeImportante={mensajeImportante}
            />
          </div>

          <div className={helpModal ? 'modal-wrapper active' : 'modal-wrapper'}>
            <div className="modal help">
              <div className="head">
                <span onClick={() => setHelpModal(false)}>
                  <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="close" />
                </span>
              </div>
              <div className="content">
                <p>Si tienes algún <b>problema con tu facturación</b> con gusto te ayudáremos en nuestro call center: <b>(222) 273 83 00 </b>o nuestro correo electrónico: <b>facturacion@estrellaroja.com.mx</b> </p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 464">
                  <path d="M208,216h16v16H208Z" />
                  <path d="M256,216h16v16H256Z" />
                  <path d="M344,367.2A56,56,0,0,0,310.74,316L272,298.8V287.34A64.47,64.47,0,0,0,289.88,272H312a16,16,0,0,0,16-16V236.94a31.4,31.4,0,0,0,0-41.88V184a88,88,0,0,0-176,0v11.06A31.84,31.84,0,0,0,176,248c.69,0,1.37-.15,2.06-.19A64.13,64.13,0,0,0,208,287.34V298.8L169.26,316A56,56,0,0,0,136,367.2V416H120v16H360V416H344ZM299.29,256a65.69,65.69,0,0,0,2.65-8.19c.69,0,1.37.19,2.06.19a31.41,31.41,0,0,0,8-1.14V256ZM320,216a16,16,0,0,1-16,16V200A16,16,0,0,1,320,216ZM189.1,133.1A72,72,0,0,1,312,184v1.14a31.41,31.41,0,0,0-8-1.14c-.69,0-1.37.15-2.06.19a63.9,63.9,0,0,0-123.88,0c-.69,0-1.37-.19-2.06-.19a31.41,31.41,0,0,0-8,1.14V184a71.56,71.56,0,0,1,21.1-50.9ZM287.28,192h-4l-13.66-13.66a8,8,0,0,0-9.23-1.5L230.11,192H192.72a47.94,47.94,0,0,1,94.56,0ZM160,216a16,16,0,0,1,16-16v32A16,16,0,0,1,160,216Zm32,16V208h40a8.08,8.08,0,0,0,3.58-.84l26.82-13.42,11.92,11.92A8,8,0,0,0,280,208h8v24a47.63,47.63,0,0,1-6.5,24H240v16h26.48A48,48,0,0,1,192,232Zm48,64a64,64,0,0,0,16-2.1V304a16,16,0,0,1-32,0V293.9A64,64,0,0,0,240,296Zm-88,71.2a40,40,0,0,1,23.75-36.55l34.4-15.3a31.86,31.86,0,0,0,59.65,0l34.4,15.3A40,40,0,0,1,328,367.2V416H152Z" />
                  <path d="M88,32h16V48H88Z" />
                  <path d="M120,32h16V48H120Z" />
                  <path d="M152,32h16V48H152Z" />
                  <path d="M464,96H440V24A24,24,0,0,0,416,0H64A24,24,0,0,0,40,24V128H16A16,16,0,0,0,0,144v64a16,16,0,0,0,16,16H40V440a24,24,0,0,0,24,24H416a24,24,0,0,0,24-24V192h24a16,16,0,0,0,16-16V112A16,16,0,0,0,464,96ZM64,16H416a8,8,0,0,1,8,8V64.26H56V24A8,8,0,0,1,64,16ZM16,144h96v64H96a8,8,0,0,0-8,8v12.69L69.66,210.34A8,8,0,0,0,64,208H16ZM424,440a8,8,0,0,1-8,8H64a8,8,0,0,1-8-8V224h4.69l29.65,29.66A8,8,0,0,0,104,248V224h8a16,16,0,0,0,16-16V144a16,16,0,0,0-16-16H56V80.26H424V96H368a16,16,0,0,0-16,16v64a16,16,0,0,0,16,16h8v24a8,8,0,0,0,13.66,5.66L419.31,192H424Zm40-264H416a8,8,0,0,0-5.66,2.34L392,196.69V184a8,8,0,0,0-8-8H368V112h96Z" />
                  <path d="M384,120h64v16H384Z" />
                  <path d="M384,152h64v16H384Z" />
                  <path d="M32,152H96v16H32Z" />
                  <path d="M32,184H96v16H32Z" />
                </svg>
              </div>
            </div>
          </div>

          <div className={stepsData.infoMessage.show ? 'modal-wrapper active' : 'modal-wrapper'}>
            <div className="modal error">
              <div className="head">
                {stepsData.infoMessage.title && <h5>{stepsData.infoMessage.title}</h5>}
                <span onClick={() => showErrorModal('info')} style={{ cursor: 'pointer' }}>
                  <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="close" />
                </span>
              </div>
              <div className="content">
                <p>{stepsData.infoMessage.message}</p>
                {!stepsData.infoMessage.title
                  ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 559.53 492.42">
                    <path d="M515.12,126.73H396.59V44.4A44.46,44.46,0,0,0,352.18,0H44.4A44.45,44.45,0,0,0,0,44.4V166.46a8.2,8.2,0,0,0,16.39,0V44.4a28,28,0,0,1,28-28H352.18a28,28,0,0,1,28,28V261.66a28,28,0,0,1-28,28H174.89a17.12,17.12,0,0,0-12.2,5.06L108.38,349a.77.77,0,0,1-.94.19.75.75,0,0,1-.53-.8V306.92a17.26,17.26,0,0,0-17.24-17.25H44.4a28,28,0,0,1-28-28V199.24a8.2,8.2,0,0,0-16.39,0v62.42a44.46,44.46,0,0,0,44.4,44.41H89.67a.85.85,0,0,1,.85.85v41.51A17.25,17.25,0,0,0,120,360.63l43-43v70.73a44.46,44.46,0,0,0,44.41,44.41h99.26a8.2,8.2,0,1,0,0-16.39H207.35a28.05,28.05,0,0,1-28-28V306.07H352.18a44.46,44.46,0,0,0,44.41-44.41V143.12H515.12a28.05,28.05,0,0,1,28,28V388.39a28.05,28.05,0,0,1-28,28H469.86a17.26,17.26,0,0,0-17.25,17.24v41.52a.85.85,0,0,1-1.46.6l-54.31-54.31a17.11,17.11,0,0,0-12.19-5H339.4a8.2,8.2,0,1,0,0,16.39h45.25a.86.86,0,0,1,.6.25l54.31,54.31A17.25,17.25,0,0,0,469,475.17V433.65a.85.85,0,0,1,.86-.85h45.26a44.46,44.46,0,0,0,44.4-44.41V171.14a44.46,44.46,0,0,0-44.41-44.41Z" />
                    <path d="M253.46,352.18a26.3,26.3,0,1,0,26.3-26.3A26.33,26.33,0,0,0,253.46,352.18Zm36.21,0a9.91,9.91,0,1,1-9.91-9.9A9.91,9.91,0,0,1,289.67,352.18Z" />
                    <path d="M172,243.56a26.3,26.3,0,1,0,26.3-26.3,26.33,26.33,0,0,0-26.3,26.3Zm36.21,0a9.91,9.91,0,1,1-9.91-9.91,9.91,9.91,0,0,1,9.91,9.91Z" />
                    <path d="M224.59,155.44a62.51,62.51,0,1,0-88.81-56.72,26.3,26.3,0,1,0,52.6,0,9.68,9.68,0,0,1,3.16-7.25,9.91,9.91,0,0,1,7.51-2.63A10.1,10.1,0,0,1,208.17,98a10,10,0,0,1-6.12,9.94A48.52,48.52,0,0,0,172,153v18.11a26.3,26.3,0,0,0,52.6,0v-15.7Zm-8.78-14.05A12.46,12.46,0,0,0,208.2,153v18.11a9.91,9.91,0,1,1-19.81,0V153a32.18,32.18,0,0,1,19.89-30,26.32,26.32,0,0,0,16.25-26.23,26.66,26.66,0,0,0-24.36-24.35c-.62,0-1.25-.06-1.86-.06A26.32,26.32,0,0,0,172,98.72a9.91,9.91,0,1,1-19.81,0,46.12,46.12,0,1,1,63.63,42.67Z" />
                    <path d="M469,352.18a26.3,26.3,0,1,0-26.3,26.3A26.33,26.33,0,0,0,469,352.18Zm-36.21,0a9.91,9.91,0,1,1,9.91,9.91A9.91,9.91,0,0,1,432.8,352.18Z" />
                    <path d="M361.24,378.48a26.3,26.3,0,1,0-26.31-26.3A26.33,26.33,0,0,0,361.24,378.48Zm0-36.2a9.91,9.91,0,1,1-9.91,9.9A9.91,9.91,0,0,1,361.24,342.28Z" />
                  </svg>
                  : <br />
                }
              </div>
              <div className="action">
                <button className="primary" onClick={() => showErrorModal('info')}>Cerrar</button>
              </div>
            </div>
          </div>

          <div className={stepsData.error.show ? 'modal-wrapper active' : 'modal-wrapper'}>
            <div className="modal error">
              <div className="head">
                <span onClick={() => showErrorModal('error')} style={{ cursor: 'pointer' }}>
                  <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="close" />
                </span>
              </div>
              <div className="content">
                <h4>Ups...</h4>
                <p>{stepsData.error.message}</p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 559.53 492.42">
                  <path d="M515.12,126.73H396.59V44.4A44.46,44.46,0,0,0,352.18,0H44.4A44.45,44.45,0,0,0,0,44.4V166.46a8.2,8.2,0,0,0,16.39,0V44.4a28,28,0,0,1,28-28H352.18a28,28,0,0,1,28,28V261.66a28,28,0,0,1-28,28H174.89a17.12,17.12,0,0,0-12.2,5.06L108.38,349a.77.77,0,0,1-.94.19.75.75,0,0,1-.53-.8V306.92a17.26,17.26,0,0,0-17.24-17.25H44.4a28,28,0,0,1-28-28V199.24a8.2,8.2,0,0,0-16.39,0v62.42a44.46,44.46,0,0,0,44.4,44.41H89.67a.85.85,0,0,1,.85.85v41.51A17.25,17.25,0,0,0,120,360.63l43-43v70.73a44.46,44.46,0,0,0,44.41,44.41h99.26a8.2,8.2,0,1,0,0-16.39H207.35a28.05,28.05,0,0,1-28-28V306.07H352.18a44.46,44.46,0,0,0,44.41-44.41V143.12H515.12a28.05,28.05,0,0,1,28,28V388.39a28.05,28.05,0,0,1-28,28H469.86a17.26,17.26,0,0,0-17.25,17.24v41.52a.85.85,0,0,1-1.46.6l-54.31-54.31a17.11,17.11,0,0,0-12.19-5H339.4a8.2,8.2,0,1,0,0,16.39h45.25a.86.86,0,0,1,.6.25l54.31,54.31A17.25,17.25,0,0,0,469,475.17V433.65a.85.85,0,0,1,.86-.85h45.26a44.46,44.46,0,0,0,44.4-44.41V171.14a44.46,44.46,0,0,0-44.41-44.41Z" />
                  <path d="M253.46,352.18a26.3,26.3,0,1,0,26.3-26.3A26.33,26.33,0,0,0,253.46,352.18Zm36.21,0a9.91,9.91,0,1,1-9.91-9.9A9.91,9.91,0,0,1,289.67,352.18Z" />
                  <path d="M172,243.56a26.3,26.3,0,1,0,26.3-26.3,26.33,26.33,0,0,0-26.3,26.3Zm36.21,0a9.91,9.91,0,1,1-9.91-9.91,9.91,9.91,0,0,1,9.91,9.91Z" />
                  <path d="M224.59,155.44a62.51,62.51,0,1,0-88.81-56.72,26.3,26.3,0,1,0,52.6,0,9.68,9.68,0,0,1,3.16-7.25,9.91,9.91,0,0,1,7.51-2.63A10.1,10.1,0,0,1,208.17,98a10,10,0,0,1-6.12,9.94A48.52,48.52,0,0,0,172,153v18.11a26.3,26.3,0,0,0,52.6,0v-15.7Zm-8.78-14.05A12.46,12.46,0,0,0,208.2,153v18.11a9.91,9.91,0,1,1-19.81,0V153a32.18,32.18,0,0,1,19.89-30,26.32,26.32,0,0,0,16.25-26.23,26.66,26.66,0,0,0-24.36-24.35c-.62,0-1.25-.06-1.86-.06A26.32,26.32,0,0,0,172,98.72a9.91,9.91,0,1,1-19.81,0,46.12,46.12,0,1,1,63.63,42.67Z" />
                  <path d="M469,352.18a26.3,26.3,0,1,0-26.3,26.3A26.33,26.33,0,0,0,469,352.18Zm-36.21,0a9.91,9.91,0,1,1,9.91,9.91A9.91,9.91,0,0,1,432.8,352.18Z" />
                  <path d="M361.24,378.48a26.3,26.3,0,1,0-26.31-26.3A26.33,26.33,0,0,0,361.24,378.48Zm0-36.2a9.91,9.91,0,1,1-9.91,9.9A9.91,9.91,0,0,1,361.24,342.28Z" />
                </svg>
              </div>
              <div className="action">
                <button className="primary" onClick={() => showErrorModal('error')}>Cerrar</button>
              </div>
            </div>
          </div>
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default Facturacion;

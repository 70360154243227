import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectBrand } from '../reducers/brandingSlice';
import { getCfdi, getFile } from '../services/main';

export function MisFacturas({ showErrorModal, setLoading, loading }) {
  /**
   * Brand/Theming 
   */
  const brand = useSelector(selectBrand);

  /**
   * Handle form
   */
  const [formValid, setFormValid] = useState(false);
  const [facturas, setFacturas] = useState();
  const [consulta, setConsulta] = useState({
    rfc: '',
    folio: '',
    fecha: '',
  });

  /**
   * Handle input change and checks if all the fields are filled so we can continue
   */
  const handleChange = (event) => {
    const target = event.target
    const inputs = document.getElementsByClassName('cosulta-inputs');
    const inputsArray = Array.from(inputs);
    let inputsValid = true;

    setConsulta({
      ...consulta,
      [target.id]: target.value
    })

    inputsArray.forEach(input => {
      if (input.value === '') {
        inputsValid = false
      }
    });

    if (inputsValid) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }

  /**
   * Get file form S3 AWS
   */
  const getFileByUrl = async (factura, fileType) => {
    let returningFile;
    if (factura[`Nombre${fileType}`] && factura[`Nombre${fileType}`] !== '') {
      const url = factura[`Ruta${fileType}`] + factura[`Nombre${fileType}`];
      const file = await getFile(url);
      const json = await file.json();
      if (json.message === 'OK' && json.data && json.data !== '') {
        returningFile = json;
      } else {
        showErrorModal('error', json.error);
      }
    }
    return returningFile;
  }

  /**
   * Fomat the date 
   * @param date
   */
  const formatDate = (date) => {
    const dateObj = new Date(date);
    const dateFomatted = dateObj.toLocaleDateString("es-MX")
    return dateFomatted;
  }

  /**
   * Submit the request and returns the data
   */
  const handleSubmit = async () => {
    //Se validan los datos de la forma
    let errorValidacion = '';
    if (!consulta.rfc) {
      errorValidacion = "El RFC es requerido";
    } else if (!consulta.rfc.match(/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/)) {
      errorValidacion = "El RFC tiene un formato inválido";
    } else if (!consulta.folio) {
      errorValidacion = "El folio del boleto es requerido";
    } else if (!consulta.folio.match(/^[a-zA-Z0-9-]*$/)) {
      errorValidacion = "El formato del folio es incorrecto";
    } else if (!consulta.fecha) {
      errorValidacion = "La fecha de venta de boleto es requerida";
    } else if (!consulta.fecha.match(/^(20[0-9]{2})-(0[1-9]|1[012])-([123]0|[012][1-9]|31)$/)) {
      errorValidacion = "La fecha de venta de boleto es inválida";
    }

    if (errorValidacion) {
      showErrorModal('error', errorValidacion);
    } else {
      setLoading(true);
      const resp = await getCfdi({ ...consulta, rfc: encodeURIComponent(consulta.rfc) });
      const json = await resp.json();
      if (json && json.isSuccess && json.data.length !== 0) {
        const data = new Promise((resolve, reject) => {
          json.data.map(async factura => {
            try {
              const xmlFile = await getFileByUrl(factura, 'XML');
              const pdfFile = await getFileByUrl(factura, 'PDF');
              factura.xmlFile = xmlFile.data;
              factura.pdfFile = pdfFile.data;
              resolve(json.data);
            }
            catch {
              return false;
            }
          })
        })

        data.then(resp => {
          setLoading(false);
          if (resp !== false) {
            setFacturas(resp)
          } else {
            showErrorModal('error');
          }
        })
      } else {
        if (json.isSuccess && json.data.length === 0) {
          showErrorModal('error', '¡Lo sentimos! No encontramos resultados con los datos proporcionados, te pedimos verificarlos e intentar nuevamente')
        } else {
          setLoading(false);
          showErrorModal('error', json.error)
        }
      }
    }
  }

  return (
    <section className="mis-facturas">
      {brand === 'ER' &&
        <>
          <div className='pleca'>
            <img src={`${process.env.PUBLIC_URL}/assets/pleca-ER.svg`} alt="detail" />
          </div>
          <h2>&nbsp;</h2>
        </>
      }
      
      <h1>Mis Facturas</h1>
      <div className="wrapper">
        <p>Introduzca la información correspondiente a un boleto para buscar todas sus facturas generadas</p>
        <div className="form">
          <div className="input-wrapper">
            <input value={consulta.rfc} className="consulta-input" onChange={(e) => handleChange(e)} id="rfc" type="text" placeholder="RFC" maxLength="13" />
          </div>
          <div className="input-wrapper">
            <input value={consulta.folio} className="consulta-input" onChange={(e) => handleChange(e)} id="folio" type="text" placeholder="Número de Boleto o Folio" maxLength="25" />
          </div>
          <div className="input-wrapper">
            <input value={consulta.fecha} className="consulta-input" onChange={(e) => handleChange(e)} onFocus={(e) => (e.currentTarget.type = "date")} onBlur={(e) => (e.currentTarget.type = "text")} id="fecha" type="text" placeholder="Fecha de Venta" min="2000-01-01" max="2099-12-31" />
          </div>
          <div className="action">
            <button disabled={!formValid} className="primary" onClick={handleSubmit}>Consultar</button>
          </div>
        </div>

        <div className="list">
          {facturas && facturas.length > 0 &&
            facturas.map((factura, index) => {
              return <div className="row" key={index}>
                <div className="dato">
                  <p><b>Folio</b></p>
                  <p>{factura.Folio}</p>
                </div>
                <div className="dato">
                  <p><b>Fecha</b></p>
                  <p>{formatDate(factura.FechaVenta)}</p>
                </div>
                <div className="dato">
                  <p><b>Tipo</b></p>
                  <p>{factura.IdTicket}</p>
                </div>
                <div className="dato">
                  <p><b>Total</b></p>
                  <p>${factura.Total}</p>
                </div>
                <div className="descargas">
                  <div className="buttons">
                    <button id="download-option">
                      <img src={`${process.env.PUBLIC_URL}/assets/download.svg`} alt="download" />
                      <div className="downloads-env">
                        {factura.pdfFile &&
                          <a target="_blank" rel="noreferrer" download="factura.pdf" href={factura.pdfFile}>PDF</a>
                        }
                        {factura.xmlFile &&
                          <a target="_blank" rel="noreferrer" download="factura.xml" href={factura.xmlFile}>XML</a>
                        }
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            })
          }
        </div>
      </div>
    </section>
  )
}
const url = process.env.REACT_APP_API_URL;
const headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');
headers.append('Origin','http://localhost:3000');

export const getDestinos = () => {
  const req = new Request(`${url}/b2bmx-ws-af-ger/Dataservices/Autofactura/Catalogos/XML/GER/Destinos?nodos=parada,camion`)
  return fetch(req)
}

export const getTypeTicket = () => {
  const req = new Request(`${url}/b2bmx-ws-af-ger/Dataservices/Autofactura/Catalogos/listarTiposTicket/GER`)
  return fetch(req)
}

export const getTicket = (body, tipo) => {
  const req = new Request(`${url}/b2bmx-ws-af-ger/Dataservices/Autofactura/Catalogos/obtenerTicket/GER?tipo=${tipo}&claveTicket=${body.tipoTicket}&folio=${body.folio}&destino=${body.destino}&precio=${body.precio}&codigoFacturacion=${body.codigoFacturacion}&fechaVenta=${body.fechaVenta}`)
  return fetch(req)
}

export const getUsoCfdi = (rfc = '', cveRegimenFiscal = '') => {
  const req = new Request(`${url}/b2bmx-ws-af-ger/Dataservices/Autofactura/Catalogos/listarUsoCfdi?rfc=${rfc}&regimenFiscal=${cveRegimenFiscal}`)
  return fetch(req)
}
export const getRegimenFiscal = (rfc) => {
  const req = new Request(`${url}/b2bmx-ws-af-ger/Dataservices/Autofactura/Catalogos/listarRegimenFiscal/${rfc}`)
  return fetch(req)
}

export const getFactura = (body) => {
  const postConfig = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body)
  }
  return fetch(`${url}/b2bmx-ws-af-ger/Dataservices/Autofactura/Operacion/generarCfdi`, postConfig)
}

export const getCfdi = (body) => {
  const req = new Request(`${url}/b2bmx-ws-af-ger/Dataservices/Autofactura/Operacion/listarCfdi/filtro?rfc=${body.rfc}&folio=${body.folio}&fecha=${body.fecha}`)
  return fetch(req)
}

export const getFile = (fileUrl) => {
  const req = new Request(`${url}/b2bmx-ws-af-ger/Dataservices/Autofactura/Operacion/obtenerRutaS3?keyUrl=${fileUrl}`)
  return fetch(req)
}

export const getReceptor = (receptor) => {
  const req = new Request(`${url}/b2bmx-ws-af-ger/Dataservices/Autofactura/Catalogos/obtenerReceptor/${receptor}`)
  return fetch(req)
}

export const getCurrentPublication = (profile) => {
  const req = new Request(`${url}/b2bmx-ws-af-ger/Dataservices/configuracion/obtenerPublicacionActual/GER/${profile}`)
  return fetch(req)
}

export const getRedContacto= (profile) => {
  const req = new Request(`${url}/b2bmx-ws-af-ger/Dataservices/configuracion/obtenerRedContacto/GER/${profile}`)
  return fetch(req)
}
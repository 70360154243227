import React, { useEffect, useState } from 'react';
import { ModalInformacion } from './modal-informacion';
import { useSelector, useDispatch } from 'react-redux';
import { selectBrand } from '../../reducers/brandingSlice';
import Select from 'react-select';
import { fetchDestinos, fetchTicketsTypes, setTicketsAdded, setTicketsSelected, stepsState } from '../../reducers/stepsSlice';
import { getTicket } from '../../services/main';
import { constants } from '../constants';
import { customStylesSelect } from '../../utils/Select';

export function IngresaDatos(props) {
  /**
   * Brand/Theming 
   */
  const brand = useSelector(selectBrand);

  /**
   * Step Handle
   */
  const dispatch = useDispatch();
  const stepsData = useSelector(stepsState);

  /**
   * View Handle
   */
  const [facturaType, setFacturaType] = useState('codigo');
  const [modal, setModal] = useState(false);

  /**
   * Form inputs
   */
  const [destino, setDestino] = useState('');
  const [folio, setFolio] = useState('');
  const [precio, setPrecio] = useState('');
  const [fechaVenta, setFechaVenta] = useState('');
  const [tipoTicket, setTipoTicket] = useState(constants.CLAVE_AUTOBUS);
  const [codigoFacturacion, setCodigoFacturacion] = useState('');

  /**
   * Tickets
   */
  const [tickets, setTickets] = useState();

  /**
   * Agregar ticket
   */
  const [cargandoAgregarTicket, setCargandoAgregarTicket] = useState(false);

  /**
   * Opens Information modal and set tickets in the state
   */
  const handleModal = () => {
    const selected = [];
    let valid = false;
    tickets.forEach(ticket => {
      if (ticket.selected) {
        selected.push(ticket);
        valid = true;
      }
    });
    if (valid) {
      dispatch(setTicketsSelected(selected));
      setModal(!modal)
    } else {
      props.showErrorModal('error', 'Selecciona al menos un boleto para continuar');
    }
  }

  /**
   * Sets the information type and resets the other inputs
   */
  const changeFacturaType = (type) => {
    if (type !== facturaType) {
      setFacturaType(type);
      setDestino('');
      setPrecio('');
      setFechaVenta('');
      setFolio('');
      setCodigoFacturacion('');
    }
  }

  /**
   * Sets the information type and resets the other inputs
   */
  const changeValue = (event) => {
    const target = event.target;
    switch (target.id) {
      case 'Destino':
        setDestino(target.value.value);
        break;
      case 'Folio':
        setFolio(target.value);
        break;
      case 'Precio':
        //Se valida longitud de campo ya que input tipo number no respeta maxLength
        if (target.value.length > 10) target.value = target.value.slice(0, 10);
        setPrecio(target.value);
        break;
      case 'FechaVenta':
        setFechaVenta(target.value);
        break;
      case 'Codigo':
        setCodigoFacturacion(target.value);
        break;
      case 'tipoTicket':
        setTipoTicket(target.value);
        if (target.value === constants.CLAVE_ESTACIONAMIENTO) {
          if (facturaType !== 'datos') { changeFacturaType('datos') }
        } else {
          changeFacturaType('codigo')
        }
        break;
      default:
        break;
    }
  }

  /**
   * Compare the tickets arrays so we can see if a ticket is already on the list
   */
  const compareTickets = (ticketsB) => {
    const ticketsEqual = [];
    for (let i = 0; i < tickets.length; i++) {
      const ticketA = tickets[i];
      for (let i = 0; i < ticketsB.length; i++) {
        const ticketB = ticketsB[i];
        if (ticketA.IdTicket === ticketB.IdTicket) {
          ticketsEqual.push(ticketB.Folio);
        }
      }
    }
    return ticketsEqual;
  }

  /**
   * Add a ticket to the list
   * it shows an error if the ticket its already on the list
   * we format the date and add the destination to the ticket
   */
  const addTicket = async () => {
    let body = {
      folio: folio,
      destino: destino,
      precio: precio,
      fechaVenta: fechaVenta,
      codigoFacturacion: codigoFacturacion,
      tipoTicket: brand === constants.EBUS_KEY ? constants.CLAVE_AUTOBUS : tipoTicket
    }

    const tipo = facturaType === 'datos' ? 1 : 2;

    //Se validan los campos capturados
    let errorValidacion = '';
    switch (tipoTicket) {
      case constants.CLAVE_AUTOBUS:
        switch (tipo) {
          case 1:
            if (!folio) {
              errorValidacion = "El folio del boleto es requerido";
            } else if (!folio.match(/^[a-zA-Z0-9-]*$/)) {
              errorValidacion = "El formato del folio es incorrecto";
            } else if (!destino) {
              errorValidacion = "El destino del boleto es requerido";
            } else if (!precio) {
              errorValidacion = "El precio del boleto es requerido";
            } else if (!precio.match(/^[0-9]+([\.][0-9]{1,2})?$/)) {
              errorValidacion = "El formato del precio es incorrecto";
            }
            break;
          case 2:
            if (!codigoFacturacion) {
              errorValidacion = "El código de facturación es requerido";
            } else if (!codigoFacturacion.match(/^[a-zA-Z0-9-]*$/)) {
              errorValidacion = "El formato del código de facturación es incorrecto";
            }
            break;
          default:
            break;
        }
        break;
      case constants.CLAVE_BONOBUS:
      case constants.CLAVE_PASAPORTE:
      case constants.CLAVE_VENTA_MISCELANEA:
        if (!codigoFacturacion) {
          errorValidacion = "El código de facturación es requerido";
        }
        break;
      case constants.CLAVE_ESTACIONAMIENTO:
        if (!folio) {
          errorValidacion = "El folio del boleto es requerido";
        } else if (!fechaVenta) {
          errorValidacion = "La fecha de venta del boleto es requerida";
        } else if (!fechaVenta.match(/^(20[0-9]{2})-(0[1-9]|1[012])-([123]0|[012][1-9]|31)$/)) {
          errorValidacion = "La fecha de venta de boleto es inválida";
        }
        break;
      default:
        break;
    }

    if (errorValidacion) {
      props.showErrorModal('error', errorValidacion);
    } else {
      setCargandoAgregarTicket(true);
      const ticketResponse = await getTicket(body, tipo);
      const json = await ticketResponse.json();
      setCargandoAgregarTicket(false);
      if (json.isSuccess) {
        const ticketsData = json.data;
        ticketsData.forEach(ticketData => {
          const date = new Date(ticketData.FechaVenta);
          ticketData.date = date.toLocaleDateString("es-MX");
          ticketData.selected = false;
        })
        if (tickets) {
          const ticketsAlreadyAdded = compareTickets(ticketsData);
          if (ticketsAlreadyAdded.length > 0) {
            const ticketsFolios = ticketsAlreadyAdded.join(', ');
            if (ticketsAlreadyAdded.length === 1) {
              props.showErrorModal('error', `El boleto con folio: ${ticketsFolios} ya está añadido`);
            } else {
              props.showErrorModal('error', `Los boletos con folios: ${ticketsFolios} ya están añadidos`);
            }
          } else {
            setTickets([...tickets, ...ticketsData]);
            props.showErrorModal('info', 'Boleto(s) Añadido(s)');
          }
        } else {
          setTickets(ticketsData)
          props.showErrorModal('info', 'Boleto(s) Añadido(s)');
        }
      } else {
        props.showErrorModal('error', json.error);
      }
    }


  }

  /**
   * Handle the checkbox on the ticket
   */
  const selectTicket = (ticket, index) => {
    const clone = JSON.parse(JSON.stringify(tickets));
    clone[index].selected = ticket.selected ? false : true;

    setTickets(clone);
  }

  /**
   * React Hook
   */
  useEffect(() => {
    if (!stepsData.destinos || !stepsData.ticketsTypes) {
      dispatch(fetchDestinos());
      dispatch(fetchTicketsTypes());
    }
    if (tickets) {
      dispatch(setTicketsAdded(tickets));
    } else {
      setTickets(stepsData.ticketsAdded);
    }
  }, [tickets])

  const obtenerTituloParrafo = (tipoTicket) => {
    let tituloParrafo = '';
    switch (tipoTicket) {
      case constants.CLAVE_AUTOBUS:
        tituloParrafo = <span>Ingresa los siguientes datos para facturar tu boleto. <br /></span>
        break;
      case constants.CLAVE_BONOBUS:
        tituloParrafo = <span>Ingresa los siguientes datos para facturar tu Bono Bus.</span>
        break;
      case constants.CLAVE_PASAPORTE:
        tituloParrafo = <span>Ingresa los siguientes datos para facturar tu Pasaporte Digital.</span>;
        break;
      case constants.CLAVE_ESTACIONAMIENTO:
        tituloParrafo = <span>Ingresa los siguientes datos para facturar tu boleto.</span>;
        break;
      case constants.CLAVE_VENTA_MISCELANEA:
        tituloParrafo = <span>Ingresa los siguientes datos para facturar tu Venta Miscelanea.</span>;
        break;
      default:
        break;
    }
    return <p className="disclaimer">{tituloParrafo}</p>
  }
  return (
    <div className="datos">
      <div className="content">
        {(brand === constants.ER_KEY) &&
          <div className="select-wrapper">
            <label>Seleccione Tipo De Ticket O Boleto</label>
            <select onChange={(e) => changeValue(e)} name="ticket" id="tipoTicket">
              {stepsData.ticketsTypes && stepsData.ticketsTypes.length > 0 &&
                stepsData.ticketsTypes.map(type => {
                  return <option key={type.IdTipoTicket} value={type.Clave}>{type.Descripcion}</option>
                })
              }
            </select>
          </div>
        }
        {(brand === constants.ER_KEY || brand === constants.EBUS_KEY) &&
          obtenerTituloParrafo(tipoTicket)
        }
        {`${brand}_` === constants.EBUS_KEY &&
          <div className="highlight">
            <p><b>1</b></p>
            <p>Ingrese alguno de los siguientes datos que se encuentra en tu boleto.</p>
          </div>
        }
        {tipoTicket === constants.CLAVE_AUTOBUS && `${brand}_` === constants.EBUS_KEY &&
          <div className="factura-type">
            <button className={facturaType === 'codigo' ? 'active' : ''} onClick={() => changeFacturaType('codigo')}>Factura con código de facturación</button>
            <button className={facturaType === 'datos' ? 'active' : ''} onClick={() => changeFacturaType('datos')}>Factura con datos de boleto</button>
          </div>
        }
        {facturaType === 'datos' &&
          <div className="form">
            {(tipoTicket === constants.CLAVE_AUTOBUS || tipoTicket === constants.CLAVE_ESTACIONAMIENTO) &&
              <>
                <div className="input-wrapper">
                  <img src={`${process.env.PUBLIC_URL}/assets/folio.svg`} alt="folio" />
                  <input onChange={(e) => changeValue(e)} id="Folio" type="text" placeholder="# Folio" disabled={cargandoAgregarTicket} maxLength="25" />
                </div>
              </>
            }
            {tipoTicket === constants.CLAVE_AUTOBUS &&
              <>
                <div className="input-wrapper">
                  <img src={`${process.env.PUBLIC_URL}/assets/destino.svg`} alt="destino" />
                  <Select
                    styles={customStylesSelect}
                    placeholder="Destino"
                    isDisabled={cargandoAgregarTicket}
                    onChange={(e) => changeValue({ target: { value: e, id: 'Destino' } })}
                    options={stepsData.destinos
                      ?
                      [
                        ...stepsData.destinos && stepsData.destinos.length > 0 &&
                        stepsData.destinos.map(destino => ({ value: destino.Clave, key: destino.Clave, label: `${destino.Clave} - ${destino.Descripcion}` }))]
                      : [{ value: '', label: 'Destino' }]
                    }
                  />
                </div>
                <div className="input-wrapper">
                  <img src={`${process.env.PUBLIC_URL}/assets/precio.svg`} alt="precio" />
                  <input onChange={(e) => changeValue(e)} id="Precio" type="number" disabled={cargandoAgregarTicket} placeholder="Precio" />
                </div>
              </>
            }
            {(tipoTicket === constants.CLAVE_BONOBUS || tipoTicket === constants.CLAVE_PASAPORTE || tipoTicket === constants.CLAVE_VENTA_MISCELANEA) &&
              <>
                <div className="input-wrapper">
                  <input onChange={(e) => changeValue(e)} id="Codigo" disabled={cargandoAgregarTicket} type="text" placeholder="#Código de Facturación" maxLength="20" />
                </div>
              </>
            }
            {tipoTicket === constants.CLAVE_ESTACIONAMIENTO &&
              <div className="input-wrapper">
                <input onChange={(e) => changeValue(e)} id="FechaVenta" type="date" disabled={cargandoAgregarTicket} placeholder="Fecha de venta" />
              </div>
            }
          </div>
        }
        {facturaType === 'codigo' &&
          <>
            <div className="form">
              <div className="input-wrapper">
                <input onChange={(e) => changeValue(e)} id="Codigo" type="text" disabled={cargandoAgregarTicket} placeholder="#Código de Facturación" />
              </div>
            </div>
            <p className="link" onClick={props.showEjemplos}>¿Dónde encuentro mi Código de Facturación?</p>
          </>
        }
        <div className="action">
          {cargandoAgregarTicket
            ? <><div className="loader-mini">
              <div className="load"></div>
            </div>
              <br />
            </>
            : <button className="primary" onClick={addTicket}>Añadir boleto</button>
          }
        </div>

        <div className="disclaimer">
          <p>Puedes añadir varios boletos en 1 sola factura</p>
        </div>
      </div>

      <div className="list">
        <p>Selecciona los boletos que quieres facturar</p>
        <p><b>Recuerda que la vigencia para facturar tus boletos es durante el mes que lo compraste y como máximo 10 días del siguiente mes.</b></p>
        <div className="list-wrapper">
          {stepsData.ticketsAdded && stepsData.ticketsAdded.length > 0
            ? <>
              <table width="100%">
                <tr>
                  <td></td>
                  <td><b>Servicio</b></td>
                  <td><b>Origen</b></td>
                  <td><b>Destino</b></td>
                  <td><b>Folio</b></td>
                  <td><b>Fecha</b></td>
                  <td><b>Monto</b></td>
                </tr>
                {stepsData.ticketsAdded.map((ticket, index) => {
                  return <tr>
                    <td>
                      <input checked={ticket.selected} type="checkbox" onChange={() => selectTicket(ticket, index)} title="Da clic para seleccionar" />
                    </td>
                    <td>
                      <p>{ticket.Servicio}</p>
                    </td>
                    <td>
                      <p>{ticket.Origen}</p>
                    </td>
                    <td>
                      <p>{ticket.Destino}</p>
                    </td>
                    <td>
                      <p>{ticket.Folio}</p>
                    </td>
                    <td>
                      <p>{ticket.date}</p>
                    </td>
                    <td>
                      <p>${ticket.Total}</p>
                    </td>
                  </tr>
                })}
              </table>
            </>
            : <p className="empty">Sin Tickets</p>
          }
        </div>
      </div>

      <div className="action">
        <button disabled={!stepsData.ticketsAdded || (stepsData.ticketsAdded && stepsData.ticketsAdded.length === 0)} onClick={() => handleModal()} className="primary">Siguiente</button>
      </div>

      <div className={modal ? 'modal-wrapper active' : 'modal-wrapper'}>
        <ModalInformacion handleStep={props.handleStep} handleModal={handleModal} showErrorModal={props.showErrorModal} />
      </div>
    </div>
  )

}
export const customStylesSelect = {
    menu: (provided, state) => ({ ...provided, border: '1px solid', borderRaius: '0', zIndex: 2, }),
    container: (styles) => ({ ...styles, }) ,
    control: (style, s) => {
      return ({ 
        ...style, 
        borderColor: 'black',
        boxShadow: s.isFocused ? '0 0 0 1px #000' : 'none',
        height: '33px',
        minHeight: '33px',
        fontFamily: "Arial",
        fontSize: 'smaller',
        borderRaius: '0',
        '&:hover': { borderColor: 'none' } 
      })
    },
    indicatorsContainer: (style, s) => {
      return ({ ...style, height: '33px' })
    },
    valueContainer: (style, s) => {
      return ({ ...style, padding: 0, height: '33px' })
    },
    dropdownIndicator: (style, s) => {
      return ({ 
        ...style,
        padding: 2,
        color: 'black',
        fontSize: 5,
        '&:hover': { color: 'black' },
      })
    },
    indicatorSeparator: (style, s) => ({  ...style, width: '0' }),
    option: (provided, { isSelected }) => {
      return ({ 
        ...provided, 
        backgroundColor: isSelected ? 'lightgray' : '',
        margin: 0,
        padding: '.1rem .4rem .1rem 2.4rem',
        color: isSelected ? 'black' : '',
        fontSize: 'smaller',
        fontFamily: "Arial",
        '&:selected': { backgroundColor: 'lightgray', color: 'black' },
        '&:hover': { backgroundColor: 'lightgray', color: 'black' },
        '&:focused': { backgroundColor: 'lightgray', color: 'black' } 
      })
    },
    input: (style) => ({ ...style, padding: '.4rem 2.4rem' }),
    placeholder: (style) => ({ ...style, padding: '.4rem 2.4rem' }),
    singleValue: (provided, state) => ({ ...provided, padding: '.4rem 2.4rem', fontFamily: "Arial", }),
  }
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { selectBrandData, selectBrand } from '../../reducers/brandingSlice';
import { stepsState } from '../../reducers/stepsSlice';

export function Generar(props) {
  /**
   * Brand/Theming 
   */
  const brandData = useSelector(selectBrandData);
  const brand = useSelector(selectBrand);

  /**
   * Step Handle
   */
  const stepsData = useSelector(stepsState);

  /**
   * Changes the base64 to a pdf and opens it in a new window
   */
  const printPdf = (basePdf) => {
    let byteCharacters = atob(basePdf);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: 'application/pdf;base64' });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
  const descargar = (uuid, archivoXML, archivoPDF) => {
    if (archivoXML) {
      let linkXML = document.createElement('a');
      document.body.appendChild(linkXML); //required in FF, optional for Chrome
      linkXML.download = `${uuid}.xml`;
      linkXML.href = `data:text/plain;base64,${archivoXML}`;
      linkXML.click();
      linkXML.remove();
    }
    if (archivoPDF) {
      let linkXML = document.createElement('a');
      document.body.appendChild(linkXML); //required in FF, optional for Chrome
      linkXML.download = `${uuid}.pdf`;
      linkXML.href = `data:text/pdf;base64,${archivoPDF}`;
      linkXML.click();
      linkXML.remove();
    }
  }
  return (
    <section className="generar">
      <div className="wrapper">
        <div className="head">
          <h2>Tu Factura se generó correctamente y se envió al correo:</h2>
          <p>{(stepsData.facturaInformation && stepsData.facturaInformation.email) 
            ? stepsData.facturaInformation.email 
            : (stepsData.facturaInformation && stepsData.facturaInformation.emailExt)
              ? stepsData.facturaInformation.emailExt
              : ''
          }</p>
          <div className="actions multiple">
            {stepsData.factura && Array.isArray(stepsData.factura) && stepsData.factura.map((factura, index) => {
              return <div className="factura" key={index}>
                <div className="factura-info">
                  <img src={`${process.env.PUBLIC_URL}/assets/marcas/${factura.Division}.png`} alt='ER' />
                </div>
                <div className="buttons">
                  {factura.ArchivoPDF &&
                    <button onClick={() => printPdf(factura.ArchivoPDF)}>
                      <img src={`${process.env.PUBLIC_URL}/assets/print.svg`} alt="print" />
                    </button>
                  }
                  {factura.ArchivoPDF && factura.ArchivoXML &&
                    <button id="download-option" onClick={() => { descargar(factura.Uuid, factura.ArchivoXML, factura.ArchivoPDF) }}>
                      <img src={`${process.env.PUBLIC_URL}/assets/download.svg`} alt="download" />
                    </button>
                  }
                </div>
              </div>
            })}
          </div>
        </div>
        <div>
          <img 
            alt="correo"
            onClick={() => window.open("https://estrellaroja.questionpro.com/a/TakeSurvey?tt=ovm569zawKEECHrPeIW9eQ%3D%3D", "_blank")}
            src={`${process.env.PUBLIC_URL}/assets/correo/${process.env.REACT_APP_BRAND === 'EBUS' ? "ebus_opinion" : "er_opinion"}.jpg`}
            style={{ width: '100%', cursor: 'pointer' }}
          />
        </div>
        <div className="info">
          <p className="nota">Nota: al momento de descargar los archivos (PFD, XML) el navegador podría mostrar un alerta indicando que una ventana emergente ha sido bloqueda  impidiendo que se descarguen ambos archivos. Por favor, hacer clic sobre la alerta e indicarle que debe permitir las ventanas emergentes en este sitio.</p>
          <div className="contacto">
            <h2>¿No has recibido tu factura?</h2>
            <p>Con gusto te atenderemos en nuestro call center: <b>{brandData.phone}</b>  ext. 0000</p>
            <p>Correo electrónico: <b>{brandData.mail}</b></p>
          </div>
          <br />
          <p>Para brindarte un mejor servicio y seguimiento a tu solicitud, favor de indicar en el asunto el código de facturación y tipo de servicio facturado.</p>
        </div>
        <br />
        <Link to="/">Regresar a la Página Principal</Link>
      </div>
    </section>
  )
}
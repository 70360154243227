import React from 'react';
import { useSelector } from 'react-redux';
import { selectBrand } from '../reducers/brandingSlice';


export function ModalPasos(props) {
  /**
   * Brand/Theming 
   */
  const brand = useSelector(selectBrand);

  return(
    <div className="modal steps">
      <div className="head">
        <h4>Pasos para realizar una factura</h4>
        <span onClick={props.showExamples}>
          <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="close" />
        </span>
      </div>
      {brand === "ER" && 
        <div className="content">
          <div className="step">
            <div className="step-head">
              <h3>Paso 1</h3>
              <p>Selecciona Tipo De Ticket O Boleto</p>
            </div>
            <img src={`${process.env.PUBLIC_URL}/assets/paso1.jpg`} alt="example" />
          </div>
          <div className="step">
            <div className="step-head">
              <h3>Paso 2</h3>
              <p>Ingresa alguno de los siguientes datos que se encuentra en tu boleto</p>
            </div>
            <img src={`${process.env.PUBLIC_URL}/assets/paso2-1.jpg`} alt="example" />
            <p>Selecciona los tickets que quieres facturar, al finalizar da click en siguiente</p>
            <img src={`${process.env.PUBLIC_URL}/assets/paso2-2.jpg`} alt="example" />
          </div>
          <div className="step">
            <div className="step-head">
              <h3>Paso 3</h3>
              <p>Ingresa tus datos de facturación</p>
            </div>
            <img src={`${process.env.PUBLIC_URL}/assets/paso3.jpg`} alt="example" />
          </div>
          <div className="step">
            <div className="step-head">
              <h3>Paso 4</h3>
              <p>Verifica tu factura, si todo está correcto, da clic en facturar. </p>
            </div>
            <img src={`${process.env.PUBLIC_URL}/assets/paso4.jpg`} alt="example" />
          </div>
          <div className="step">
            <div className="step-head">
              <h3>Paso 5</h3>
              <p>Tu factura será enviada a tu correo electrónico en formato .xml y .pdf</p>
            </div>
            <img src={`${process.env.PUBLIC_URL}/assets/paso5.jpg`} alt="example" />
          </div>
          <div className="important">
            <h3>Importante</h3>
            <div className="info">
              <p>Después de realizar el proceso de facturación no podrás cambiar horario, punto de salida o cancelar tu viaje.</p>
              <p>Te sugerimos facturar despues de realizar tu viaje.</p>
            </div>
          </div>
        </div>
      }
      {brand === "ebus"  && 
        <div className="content">
          <div className="step">
            <div className="step-head">
              <h3>Paso 1</h3>
              <p>Ingresa alguno de los siguientes datos que se encuentra en tu boleto</p>
            </div>
            <img src={`${process.env.PUBLIC_URL}/assets/step1-ebus.jpg`} alt="example" />
            <p>Selecciona los tickets que quieres facturar, al finalizar da click en siguiente</p>
            <img src={`${process.env.PUBLIC_URL}/assets/step1-1-ebus.jpg`} alt="example" />
          </div>
          <div className="step">
            <div className="step-head">
              <h3>Paso 2</h3>
              <p>Ingresa tus datos de facturación</p>
            </div>
            <img src={`${process.env.PUBLIC_URL}/assets/step2-ebus.jpg`} alt="example" />
          </div>
          <div className="step">
            <div className="step-head">
              <h3>Paso 3</h3>
              <p>Verifica tu factura, si todo está correcto, da clic en facturar. </p>
            </div>
            <img src={`${process.env.PUBLIC_URL}/assets/step3-ebus.jpg`} alt="example" />
          </div>
          <div className="step">
            <div className="step-head">
              <h3>Paso 4</h3>
              <p>Tu factura será enviada a tu correo electrónico en formato .xml y .pdf</p>
            </div>
            <img src={`${process.env.PUBLIC_URL}/assets/step4-ebus.jpg`} alt="example" />
          </div>
          <div className="important">
            <h3>Importante</h3>
            <div className="info">
              <p>Después de realizar el proceso de facturación no podrás cambiar horario, punto de salida o cancelar tu viaje.</p>
              <p>Te sugerimos facturar despues de realizar tu viaje.</p>
            </div>
          </div>
        </div>
      }
    </div>
  )
}
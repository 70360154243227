import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectBrand } from '../../reducers/brandingSlice';
import { stepsState, fetchFactura, setFactura } from '../../reducers/stepsSlice';
import { constants } from '../constants';
import CardCarousel from '../../Slider';

export function DatosFiscales(props) {
  /**
   * Brand/Theming 
   */
  const brand = useSelector(selectBrand);
  const { showModalConfirmar, setMensajes, setHandleConfirmar } = props;

  /**
   * Step Handle
   */
  const dispatch = useDispatch();
  const stepsData = useSelector(stepsState);

  /**
   * Change the view for the selected invoice
   */
  const changeFacturaSelected = () => {
    const previews = document.getElementsByClassName('preview');
    const previewsArray = Array.from(previews);
    previewsArray.forEach(preview => {
      preview.classList.toggle('active');
    })
  }

  /**
   * Fomat the date 
   * @param date
   */
  const formatDate = (date) => {
    const dateObj = new Date(date);
    
    function join(date, opt, separator) {
      function format(m) {
        let dateF = new Intl.DateTimeFormat('en', m);
        return dateF.format(date);
      }
      return opt.map(format).join(separator);
    }
    
    let options = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}];
    let dateFormatted = join(dateObj, options, '-');
    return dateFormatted;
  }

  /**
   * Creates the invoice by calling to the service and returning the created invoice
   * If not we show an error
   */
  const facturar = () => {
    dispatch(fetchFactura(stepsData.facturacionBody)).then( async (resp) => {
      if(resp.payload.data && Array.isArray(resp.payload.data.FacturasTimbradas) && resp.payload.data.FacturasTimbradas.length > 0){
        //Se verifica si hay error en el timbrado
        let errorTimbrado = false;
        let error = ''
        let errorDetalle = '';
        let isErrorDiff = false;
        resp.payload.data.FacturasTimbradas.forEach(function(factura,index){
          if (factura.Error) {
            if (!error) {
              error = `${factura.Error}`
              errorDetalle = `${factura.DetalleError}`
            } else {
              if ((error !== factura.Error || errorDetalle !== factura.DetalleError)) {
                isErrorDiff = true; 
                error = '';
                errorDetalle = '';
              }
            }
            errorTimbrado = true;
          }
        });
        if (errorTimbrado) {
          const errorGeneral = (!isErrorDiff && error) ? `${errorDetalle && errorDetalle}` : 'Sucedió un error al timbrar la factura, por favor intenta más tarde.';
          props.showErrorModal('error', errorGeneral);
        } else {
          dispatch(setFactura(resp.payload.data.FacturasTimbradas))
          props.handleStep('generar');
        }
      }else{
        props.showErrorModal('error',resp.payload.error.DetalleError);
      }
    });
  }
  const handleShowModalConfirm = () => {
    const titulo = 'Facturar';
    const descripcion = '¿Desea confirmar la emisión de la factura?';
    const importante = 'Por favor valida que tus datos fiscales sean los mismos que los de tu cédula fiscal. Una vez timbrado el comprobante, no podrá haber corrección de datos. Si realizas la facturación antes de tu viaje, no podrás cambiar ni cancelar tu boleto.';
    setMensajes(titulo, descripcion, importante);
    setHandleConfirmar(facturar);
    showModalConfirmar();

  }
  return(
    <>
    <section className="datos-fiscales">
      <div className="wrapper">
        <div className="data">
          <p><b>Revisa tus datos para realizar tu factura final</b></p>
          <div className="preview-wrapper">
            <CardCarousel data=
            {
              stepsData.preFactura.data.map((factura, index) => {

              const isUnica = stepsData.preFactura.data.length === 1 ? true : false;
              const { Emisor, Fecha, TotalEnLetras, MetodoPago, FormaPago, DescFormaPago, Moneda, SubTotal, TotalImpuestosTrasladados, Total } = factura.Ingreso;
              const subtotal = (SubTotal.toString().search('.') !== -1 ? SubTotal.toFixed(2) : `${SubTotal}.00`) + '';
              const iva = (TotalImpuestosTrasladados.toString().search('.') !== -1 ? TotalImpuestosTrasladados.toFixed(2) : `${TotalImpuestosTrasladados}.00`) + '';
              const total = (Total.toString().search('.') !== -1 ? Total.toFixed(2) : `${Total}.00`) + '';
              return <div key={index} className={((brand === constants.ER_KEY && factura.Ingreso.Emisor.Rfc === constants.ER_IDENTIFIER) || isUnica) ? 'preview' : 'preview'}>
              <img src={`${process.env.PUBLIC_URL}/assets/marcas/${Emisor.Division}.png`} alt={brand} />

              <div className="datos-generales">
                <h2>Factura</h2>
                <div className="dato">
                  <p><b>Fecha de emisión:</b></p>
                  <p>{formatDate(Fecha)}</p>
                </div>
                <div className="dato">
                  <p><b>No. de Serie del CDS del emisor:</b></p>
                  <p>{factura.Ingreso.NoCertificado}</p>
                </div>
              </div>

              <div className="datos-fiscales">
                <h3>Emisor</h3>
                <div className="two-col">
                  <div className="dato">
                    <p><b>RFC:</b></p>
                    <p>{factura.Ingreso.Emisor.Rfc}</p>
                  </div>
                  <div className="dato">
                    <p><b>Razón social:</b></p>
                    <p>{factura.Ingreso.Emisor.Nombre}</p>
                  </div>
                </div>
                <div className="two-col">
                  <div className="dato">
                    <p><b>Domicilio Fiscal:</b></p>
                    <p>{factura.Ingreso.Emisor.Calle} {factura.Ingreso.Emisor.NumeroExterior} {factura.Ingreso.Emisor.Colonia} C.P. {factura.Ingreso.Emisor.CodigoPostal}</p>
                  </div>
                  <div className="dato">
                    <p><b>Regimen Fiscal:</b></p>
                    <p>{factura.Ingreso.Emisor.DescRegimenFiscal}</p>
                  </div>
                </div>
                <h3>Receptor</h3>
                <div className="two-col">
                  <div className="dato">
                    <p><b>RFC:</b></p>
                    <p>{factura.Ingreso.Receptor.Rfc}</p>
                  </div>
                  <div className="dato">
                    <p><b>Razón social:</b></p>
                    <p>{factura.Ingreso.Receptor.Nombre}</p>
                  </div>
                </div>
                <div className="two-col">
                  <div className="dato">
                    <p><b>Domicilio Fiscal:</b></p>
                    <p>{factura.Ingreso.Receptor.Calle} {factura.Ingreso.Receptor.NumeroExterior} {factura.Ingreso.Receptor.Colonia} C.P. {factura.Ingreso.Receptor.CodigoPostal}</p>
                  </div>
                  <div className="dato">
                    <p><b>Regimen Fiscal:</b></p>
                    <p>{factura.Ingreso.Receptor.DescRegimenFiscal}</p>
                  </div>                  
                </div>
                <div className="two-col">
                  <div className="dato">
                    <p><b>Uso del CFDI:</b></p>
                    <p>{factura.Ingreso.Receptor.DescUsoCFDI}</p>
                  </div>
                </div>                
              </div>
              <div className="resumen-wrapper">
                <div className="resumen">
                  <table width="100%">
                    <thead>
                      <tr>
                        <th>Cantidad</th>
                        <th>Unidad</th>
                        <th>Concepto</th>
                        <th>Precio Unitario</th>
                        <th>IVA %</th>
                        <th>Monto IVA</th>
                        <th>Importe</th>
                      </tr>
                    </thead>
                    <tbody>
                      {factura.Ingreso.Conceptos.map((ticket, index) => {
                        return<tr key={index}>
                          <td>{ticket.Cantidad}</td>
                          <td>{ticket.Unidad}</td>
                          <td>{ticket.Descripcion}</td>
                          <td>{ticket.ValorUnitario}</td>
                          <td>{ticket.ImpuestosTrasladados[0].TasaOCuota}</td>
                          <td>{ticket.ImpuestosTrasladados[0].Importe}</td>
                          <td>{ticket.Importe}</td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="datos-fiscales">
                <div className="two-col-3-1">
                  <div>
                    <div className="two-col-5-11">
                      <div className="dato"><p><b>Importe letra:</b></p></div>
                      <div className="dato">{TotalEnLetras}</div>
                    </div>
                    <div className="two-col-5-11">
                      <div className="dato"><p><b>Método de pago:</b></p></div>
                      <div className="dato">{MetodoPago}</div>
                    </div>
                    <div className="two-col-5-11">
                      <div className="dato"><p><b>Forma de pago:</b></p></div>
                      <div className="dato">{FormaPago} - {DescFormaPago}</div>
                    </div>
                    <div className="two-col-5-11">
                      <div className="dato"><p><b>Tipo de moneda:</b></p></div>
                      <div className="dato">{Moneda}</div>
                    </div>
                  </div>
                  <div>
                    <div className="two-col-2-2">
                      <div className="dato"><p><b>Subtotal:</b></p></div>
                      <div className="dato">{subtotal}</div>
                    </div>
                    <div className="two-col-2-2">
                      <div className="dato"><p><b>IVA:</b></p></div>
                      <div className="dato">{iva}</div>
                    </div>
                    <div className="two-col-2-2">
                      <div className="dato"><p><b>Total:</b></p></div>
                      <div className="dato">{total}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            })
            }
            />
          </div>
          <p className="disclaimer">
          <b>IMPORTANTE:</b> Por favor valida que tus datos fiscales sean los mismos que los de tu cédula fiscal. Una vez timbrado el comprobante, no podrá haber corrección de datos. Si realizas la facturación antes de tu viaje, no podrás cambiar ni cancelar tu boleto.
          </p>
          <div className="actions">
            <button onClick={handleShowModalConfirm} className="primary">Facturar</button>
            <button onClick={() => props.handleStep('ingresaDatos')}  className="secundary">Modificar datos</button>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}
import React from 'react';
import { useSelector } from 'react-redux';
import { selectBrand } from '../reducers/brandingSlice';

export function ModalEjemplos(props) {
  /**
   * Brand/Theming 
   */
  const brand = useSelector(selectBrand);

  return(
    <div className="modal ejemplo">
      <div className="head">
        <h4>Ejemplos Folio Facturación</h4>
        <span onClick={props.showEjemplos}>
          <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="close" />
        </span>
      </div>
      <div className="content">
        <img className="seal" src={`${process.env.PUBLIC_URL}/assets/${brand.toLowerCase()}-seal.svg`} alt={brand} />
        {brand === 'ER' && 
          <>
          <div className="example">
            <img src={`${process.env.PUBLIC_URL}/assets/facturacion/ejemplo-3-ER.png`} alt="ejemplo" />
            <p>Boleto comprado en Taquilla</p>
          </div>
          <div className="example">
            <img src={`${process.env.PUBLIC_URL}/assets/facturacion/ejemplo-1-ER.png`} alt="ejemplo" />
            <p>Ticket de Estacionamiento</p>
          </div>
          <div className="example">
            <img src={`${process.env.PUBLIC_URL}/assets/facturacion/ejemplo-4-ER.png`} alt="ejemplo" />
            <p>Boleto Manual</p>
          </div>
          <div className="example">
            <img src={`${process.env.PUBLIC_URL}/assets/facturacion/ejemplo-2-ER.png`} alt="ejemplo" />
            <p>Boleto comprado en Medio Digitales</p>
          </div>
          </>
        }

        {brand === 'ebus' && 
          <>
          <div className="example">
            <img src={`${process.env.PUBLIC_URL}/assets/facturacion/ejemplo-1-ebus.png`} alt="ejemplo" />
            <p>Boleto comprado en Taquilla</p>
          </div>
          <div className="example">
            <img src={`${process.env.PUBLIC_URL}/assets/facturacion/ejemplo-2-ebus.png`} alt="ejemplo" />
            <p>Boleto comprado en Medios Digitales</p>
          </div>
          </>
        }
      </div>
    </div>
  )
}
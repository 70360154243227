import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectBrand } from '../reducers/brandingSlice';
import { setStep, resetState } from '../reducers/stepsSlice';
import { getCurrentPublication } from '../services/main';

export function Home({setLoading, loading}) {
  /**
   * History Handle
   */
  let history = useHistory();

  /**
   * Dispatch
   */
   const dispatch = useDispatch();

  /**
   * Brand/Theming 
   */
  const brand = useSelector(selectBrand);
  
  /**
   * Slider Handle
   */
  const [publication, setPublication] = useState(null);
  const [sliders, setSliders] = useState([]);
  const [sliderCount, setSliderCount] = useState(0);
  const slidersClasses = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];

  /**
   * Change the active slider
   * @param {*} slide 
   */
  const changeSlide = (slide) => {
    setSliderCount(slide)
  }

  /**
   * Navigates to route
   * @param {*} route 
   */
  const goLink = (route) => {
    history.push(`/${route}`);
    dispatch(setStep('ingresaDatos'));
    dispatch(resetState());
    window.scrollTo(0,0);
  }

  /**
   * React Hook
   */
  useEffect(() => {
    /**
     * Get Sliders
     */
    if(!publication) {
      const brandkey = process.env.REACT_APP_BRAND === 'ER' ? 'estrellaRoja' : 'ebus';
      getCurrentPublication(brandkey).then(async resp => {
        const data = await resp.json();
        if(data.isSuccess){
            setPublication(data.data);
            const sortData = data.data.contenido.sort((a,b) => {
              return a.posicion - b.posicion
            })
            setSliders(sortData);
        }
        setLoading(false);
      })
    }

    /**
     * Slider Interval
     */
    const interval = setInterval(() => {
      if (publication) {
        if(sliderCount === publication.contenido.length-1){
          setSliderCount(() => 0)
        }else{
          setSliderCount(sliderCount => sliderCount +1);
        }
      }
    }, 5000);
    return () => clearInterval(interval);    
  }, [sliderCount, publication]);

  return(
    <section className="home">
      <h1>¡Bienvenido!</h1>
      <div className="wrapper">
        <div className="text">
          <h3>Genera tu factura en <b>3 sencillos pasos:</b></h3><br/>
          <h3><b>1. Ingresa tus boletos</b></h3>
          <h3><b>2. Agrega tus datos fiscales</b></h3>
          <h3><b>3. Genera tu factura</b></h3>
        </div>

        <div className="action">
          <button className="primary" onClick={() => goLink('generar-factura')}>Empezar a Facturar</button>
        </div>

        <div className="welcome-footer">
          {publication 
          ? <>
            <div className="pleca">
              {publication && 
                <img src={`data:image/svg+xml;base64,${publication.imagenBase64}`} alt="header"/>
              }
            </div>

            <div className={`slider ${slidersClasses[sliderCount]}`}>
              {sliders && 
                <nav>
                  {sliders.map((slide, index) => {
                    return <button key={index} className={sliderCount === index ? 'active' : ''} onClick={() => changeSlide(index)}></button>
                  })}
                </nav>
              }
              {sliders && 
                <div className="slider-wrapper">
                  {sliders.map((contenido, index) => {
                    return <div className="slide" key={index}>
                      <a href={contenido.urlRedirect} target="_blank">
                      <img src={`data:image/png;base64,${contenido.imagenBase64}`} alt="slide" />
                      </a>
                    </div>
                  })}
                </div>
              }
            </div>
          </>
          :<>
            <div className="loader-mini">
              <div className="load"></div>
            </div>
          </>
          }
        </div>
      </div>
    </section>
  )
}

export default Home